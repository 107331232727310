/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
import React from 'react';
import * as XLSX from 'xlsx';
import { Grid, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../TAS1/Form/tas1';
import { BULKUPLOAD_TAS, GET_MASTER_TEMPLATES, INSERT_TAS_CHECK } from '../../../../constants/actionType/index';
import Loader from '../../../../components/atoms/Loader';
import Toaster from '../../../../components/atoms/ToastMessage';
import FileUpload from '../../../../components/molecules/FileUpload';
import Table from '../TASTable';
import './styleSheet.css';
// import CustomIcons from '../../../../utils/icons';

/**
 *@param {*} props --required
 *@name TAS1
 *@returns {React.ReactElement} return the index page of TAS1
 */
const TAS2Form = (props) => {
  const { onView, onClickHandleView, setJsonData } = props;
  const [file, setFile] = React.useState();
  const [upload, setUpload] = React.useState(false);
  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload } = Actionvalue?.input?.[0];

  const dispatch = useDispatch();
  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = async (files) => {
    setFile(files);
    const data = await files.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(worksheet);
    setJsonData(json);
  };
  React.useEffect(() => {
    dispatch({ type: GET_MASTER_TEMPLATES });
  }, [dispatch]);
  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: BULKUPLOAD_TAS, payload: file });
    dispatch({ type: GET_MASTER_TEMPLATES });
  };
  return (
    <Grid container item md={12} spacing={3} sx={{ paddingTop: 2, height: '100%' }}>
      <Grid item md={upload ? 9 : 11.4} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <Form viewAction={onView} Activity="TAS2" />
        </Paper>
      </Grid>
      {upload === false && (
        <Tooltip title="File Upload " placement="top">
          <Grid
            onClick={() => BulkUpload === 1 && setUpload(true)}
            padding="10px"
            marginTop="40px"
            className="uploadContainer"
          >
            {/* <img src={CustomIcons.LeftArrow} alt="upload" className="uploadImgFile" /> */}
            {/* <p className="uploadFile">File Upload</p> */}
            <KeyboardDoubleArrowLeftIcon fontSize="large" color="primary" className="uploadImgFile" />
          </Grid>
        </Tooltip>
      )}
      {upload === true && (
        <Grid item md={3} sm={12} xs={12} className="layoutFormat">
          <Paper elevation={3} className="splitPage">
            <FileUpload
              templateUrl={getMasterTemplate.TasDistrict}
              excelUploader
              downloadTemplate
              sendSelectedFile={(files) => sendSelectedFiles(files)}
              onSubmitFiles={onSubmitFiles}
              handleCancel={() => setUpload(false)}
              onClickHandleView={onClickHandleView}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
TAS2Form.propTypes = {
  onView: PropTypes.func,
};
TAS2Form.defaultProps = {
  onView: () => {},
};

/**
 * @param {Function} onActionHandle --required
 * @returns {React.ReactElement} --
 */
const TAS2Table = ({ onCancelAction, onClickHandleView, setJsonData, jsonData, field, setIsJson, isJson }) => {
  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);

  const [file, setFile] = React.useState();
  const [upload, setUpload] = React.useState(false);
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload } = Actionvalue?.input?.[0];

  const dispatch = useDispatch();
  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = async (files) => {
    setFile(files);
    const data = await files.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(worksheet);
    setJsonData(json);
  };
  React.useEffect(() => {
    dispatch({ type: GET_MASTER_TEMPLATES });
  }, [dispatch]);
  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: BULKUPLOAD_TAS, payload: file });
    dispatch({ type: GET_MASTER_TEMPLATES });
  };
  return (
    <Grid container item md={12} spacing={3} sx={{ paddingTop: 2, height: '100%' }}>
      <Grid item md={upload ? 9 : 11.4} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <Table
            onCancelAction={(e) => onCancelAction(e)}
            TASType="TAS2"
            Activity="TAS2"
            jsonData={jsonData}
            field={field}
            setIsJson={setIsJson}
            isJson={isJson}
          />
        </Paper>
      </Grid>
      {upload === false && (
        <Tooltip title="File Upload " placement="top">
          <Grid
            onClick={() => BulkUpload === 1 && setUpload(true)}
            padding="10px"
            marginTop="40px"
            className="uploadContainer"
          >
            {/* <img src={CustomIcons.LeftArrow} alt="upload" className="uploadImgFile" /> */}
            {/* <p className="uploadFile">File Upload</p> */}
            <KeyboardDoubleArrowLeftIcon fontSize="large" color="primary" className="uploadImgFile" />
          </Grid>
        </Tooltip>
      )}
      {upload === true && (
        <Grid item md={3} sm={12} xs={12} className="layoutFormat">
          <Paper elevation={3} className="splitPage">
            <FileUpload
              templateUrl={field === 'District Strategy' ? getMasterTemplate.TasDistrict : getMasterTemplate.TasBlock}
              downloadTemplate
              excelUploader
              sendSelectedFile={(files) => sendSelectedFiles(files)}
              onSubmitFiles={onSubmitFiles}
              handleCancel={() => setUpload(false)}
              onClickHandleView={onClickHandleView}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
TAS2Table.propTypes = {
  onCancelAction: PropTypes.func,
};
TAS2Table.defaultProps = {
  onCancelAction: () => {},
};
/**
 * @returns {React.ReactElement} --
 */
const TAS2Input = () => {
  // const ValidationGroup = localStorage.getItem('userGroupId');
  // const ValidationType = localStorage.getItem('userTypeId');
  const [form, setForm] = React.useState('form');
  const { loading, status } = useSelector((state) => state.TASDetails);
  const dispatch = useDispatch();
  const [toaster, setToaster] = React.useState(false);
  const [jsonData, setJsonData] = React.useState();
  const [prompt, setPrompt] = React.useState(false);
  const [isJson, setIsJson] = React.useState(false);

  const [field, setField] = React.useState('District Strategy');

  React.useEffect(() => {
    dispatch({ type: INSERT_TAS_CHECK });
  }, [dispatch]);

  React.useEffect(() => {
    if (status) {
      if (status.type === 'INITIAL') setToaster(false);
      else setToaster(true);
    }
  }, [status]);

  /**
   * @param {string} e input type
   */
  const onActionHandle = (e) => {
    if (e === 'table') {
      setPrompt(true);
    } else {
      setForm(e);
    }
  };
  /**
   *
   */
  const handleTableClick = (e) => {
    setPrompt(false);
    setForm('table');
    setField(e);
  };
  /**
   *
   */
  const onClickHandleView = () => {
    setForm('table');
    setIsJson(true);
  };

  /**
   *  onToasterClose used for toaser close func
   */
  const onToasterClose = () => {
    setToaster(false);
  };

  /**
   *
   * @param {*} type defines the types
   * @returns {React.ReactElement} --
   */
  const tableView = (type) => {
    switch (type) {
      case (type = 'form'):
        return (
          <TAS2Form
            onView={() => onActionHandle('table')}
            onClickHandleView={onClickHandleView}
            setJsonData={setJsonData}
          />
        );
      case (type = 'table'):
        return (
          <TAS2Table
            onCancelAction={(e) => onActionHandle(e)}
            onClickHandleView={onClickHandleView}
            setJsonData={setJsonData}
            jsonData={jsonData}
            field={field}
            isJson={isJson}
            setIsJson={setIsJson}
          />
        );
      default:
        return <TAS2Form onClickHandleView={onClickHandleView} setJsonData={setJsonData} />;
    }
  };
  if (loading) {
    return <Loader />;
  }
  return (
    <Grid className="dataLayout">
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={onToasterClose} />}
      {prompt && (
        <Dialog
          open={true}
          // onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogActions>
            <Button onClick={() => handleTableClick('District Strategy')}>District Strategy</Button>
            <Button onClick={() => handleTableClick('Block Strategy')}>Block Strategy</Button>
          </DialogActions>
        </Dialog>
      )}
      {tableView(form)}
    </Grid>
  );
};
export default TAS2Input;
