/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { Grid, Paper } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useDispatch, useSelector } from 'react-redux';
// import DAFormInput from './DA-Form';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Loader from '../../../../components/atoms/Loader';
import MDAFormInput from '../NewMDA';
import Toaster from '../../../../components/atoms/ToastMessage';
import FileUpload from '../../../../components/molecules/FileUpload';
import Table from '../../../../components/organisms/Table';
import { thData, thDataDis, thDataBlk } from './StaticEntries';
import {
  GET_IDA,
  GET_MASTER_TEMPLATES,
  INSERT_MDA_CHECK,
  UPDATE_IDA,
  UPLOAD_MDA_IDA,
} from '../../../../constants/actionType';
import './DA.css';
import MDADAFormInput from './DA-Form';
import CustomIcons from '../../../../utils/icons';

/**
 *
 * @returns {React.ReactElement} - returns the IDA form format
 */
const DAForm = () => {
  const dispatch = useDispatch();
  const [showTable, setShowTable] = React.useState(false);
  const [tablehead, setTableHead] = React.useState(thData);
  const [file, setFile] = React.useState();
  const { getIDA, loading, status } = useSelector((state) => state.IDADetails);
  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
  // console.log(getMasterTemplate, 'testttss');
  const [toaster, setToaster] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [isJson, setIsJson] = React.useState(false);
  const [jsonData, setJsonData] = React.useState();
  const ValidationGroup = localStorage.getItem('userGroupId');
  const ValidationType = localStorage.getItem('userTypeId');
  const [prompt, setPrompt] = React.useState(false);
  const [fieldValue, setFieldValue] = React.useState('');
  const [finalDataValue, setFinalDataValue] = React.useState([]);
  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload } = Actionvalue?.input?.[0];
  const userAurhId = localStorage.getItem('UserLoginId');
  // console.log(userAurhId, 'userAurhId');

  React.useEffect(() => {
    if (status) setToaster(true);
  }, [status, ValidationGroup, ValidationType]);
  React.useEffect(() => {
    dispatch({ type: INSERT_MDA_CHECK });
  }, [dispatch]);
  // console.log(finalDataValue, 'sdreertyy');

  React.useEffect(() => {
    if (status) setToaster(false);
  }, [status]);
  React.useEffect(() => {
    if (status) {
      if (status.type === 'INITIAL') {
        setToaster(false);
      } else {
        setToaster(true);
      }
    }
  }, [status]);
  /**
   *
   */
  const onClickHandleView = () => {
    setShowTable(true);
    setIsJson(true);
  };

  const tableElements = React.useMemo(() => {
    let sitesHead = {};
    return getIDA.map((data) => {
      // console.log(data, 'sl12dkjit');
      let sitesRow = {};
      data.mdaSurvey.map((item, index) => {
        // console.log(item, 'sldjlkir');
        const i = index + 1;
        if (fieldValue === 'Block Strategy') {
          sitesRow = {
            ...sitesRow,
            [`Block Name${i}`]: item.block_name1,
            [`Test Type${i}`]: item.test_type1,
            [`NBS Survey Date${i}`]: item.nbs_survey_date1,
            // [`${item.site_id_sentinel} Name`]: item.sentinel_sites_name,
            // [`BSE ${item.site_id_sentinel}`]: item.sentinel_sites_blood_count,
            // [`Positive ${item.site_id_sentinel}`]: item.sentinel_sites_possitive,
            [`S${i} Name`]: item.sentinel_sites_name1,
            [`BSE S${i}`]: item.sentinel_sites_blood_count1,
            [`Positive S${i}`]: item.sentinel_sites_possitive1,
            [`R${i} Name`]: item.sentinel_sites_name2,
            [`BSE R${i}`]: item.sentinel_sites_blood_count2,
            [`Positive R${i}`]: item.sentinel_sites_possitive2,
          };
        }
        if (fieldValue === 'District Strategy') {
          sitesRow = {
            ...sitesRow,
            [`Block Name${i}`]: item.block_name1,
            // [`Test Type${i}`]: item.test_type1,
            // [`NBS Survey Date${i}`]: item.nbs_survey_date1,
            // [`${item.site_id_sentinel} Name`]: item.sentinel_sites_name,
            // [`BSE ${item.site_id_sentinel}`]: item.sentinel_sites_blood_count,
            // [`Positive ${item.site_id_sentinel}`]: item.sentinel_sites_possitive,
            [`S${i} Name`]: item.sentinel_sites_name1,
            [`BSE S${i}`]: item.sentinel_sites_blood_count1,
            [`Positive S${i}`]: item.sentinel_sites_possitive1,
            [`Block Name2${i}`]: item.block_name2,
            [`R${i} Name`]: item.sentinel_sites_name2,
            [`BSE R${i}`]: item.sentinel_sites_blood_count2,
            [`Positive R${i}`]: item.sentinel_sites_possitive2,
          };
        }

        sitesHead = { ...sitesHead, ...sitesRow };
        return null;
      });
      setTableHead([...tablehead, ...Object.keys(sitesHead)]);
      return { ...data, ...sitesRow };
    });
  }, [getIDA]);
  React.useEffect(() => {
    dispatch({ type: GET_MASTER_TEMPLATES });
  }, [dispatch]);

  /**
   *
   */
  const onViewTableData = () => {
    setPrompt(true);
    // dispatch({ type: GET_IDA, payload: 'DA' });
  };
  // React.useEffect(() => {
  //   dispatch({ type: GET_IDA, payload: 'DA' });
  // }, [dispatch]);
  /**
   *
   * @param {*} item
   * @returns
   */
  const BlockDataDetail = (item) => {
    const arr = [];
    // console.log(arr1, 'slkdfjsirr');

    item?.map((val) => {
      const arr1 = [];
      // console.log(item, 'lksjdfsjuir');
      for (let i = 1; i <= val?.Block_Count; i++) {
        arr1.push({
          block_name: val[`Block Name${i}`] || '',
          block_date_completed:
            moment(new Date((val?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
          block_covered_population: 123,
          block_population: 43,
          test_type_id: val[`Test Type${i}`] || 'NBS',
          nbs_survey_date:
            moment(new Date((val[`NBS Survey Date${i}`] - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
          site_id1: `S${i}`,
          site_name1: val[`S${i} Name`] || '',
          site_blood_count1: val[`BSE S${i}`] || 0,
          site_possitive1: val[`Positive S${i}`] || 0,
          mf_rate1: 4.3,
          site_id2: `R${i}`,
          site_name2: val[`R${i} Name`] || '',
          site_blood_count2: val[`BSE R${i}`] || 0,
          site_possitive2: val[`Positive R${i}`] || 0,
          mf_rate2: 4.3,
        });
      }

      arr.push({
        program_activity: 'DA',
        state_id: val?.state,
        district_id: val?.district,
        strategy_id: val?.block_strategy,
        date_proposed: moment(new Date((val?.date_proposed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
        date_planned: moment(new Date((val?.date_planned - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
        date_completed: moment(new Date((val?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
        covered_population: val?.covered_population || '',
        population: val?.population || '',
        block_count: item?.length + 1,
        coverage: arr1,
      });
    });
    return arr;
  };
  React.useEffect(() => {
    const arr = [];
    jsonData?.map((item) => {
      const arr1 = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= item?.random; i++) {
        // console.log(item[`Block Name2${i}`], 'sdkslkfaksdt');

        arr1.push({
          block_name: item[`Block Name${i}`] !== undefined ? item[`Block Name${i}`] : '',
          site_id: `R${i}`,
          site_name: item[`R${i} Name`] || '',
          site_blood_count: item[`BSE R${i}`],
          site_possitive: item[`Positive R${i}`],
          mf_rate: '77',
        });
      }
      // eslint-disable-next-line no-plusplus
      for (let j = 1; j <= item?.sentinal; j++) {
        arr1.push({
          block_name: item[`Block Name2${j}`] !== undefined ? item[`Block Name2${j}`] : '',
          site_id: `S${j}`,
          site_name: item[`S${j} Name`],
          site_blood_count: item[`BSE S${j}`],
          site_possitive: item[`Positive S${j}`],
          mf_rate: '77',
        });
      }
      return arr.push({
        program_activity: 'DA',
        state_id: item?.state,
        district_id: item?.district,
        strategy_id: item?.block_strategy,
        date_proposed: moment(new Date((item?.date_proposed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
        date_planned: moment(new Date((item?.date_planned - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
        date_completed:
          moment(new Date((item?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
        covered_population: item?.covered_population || '',
        population: item?.population || '',
        sentinal: item?.sentinal || '',
        random: item?.random || '',
        test_type_id: item?.test_type,
        nbs_survey_date:
          item?.nbs_survey_date !== undefined
            ? moment(new Date((item?.nbs_survey_date - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD')
            : '',
        coverage: arr1,
      });
    });

    const res = BlockDataDetail(jsonData);
    if (jsonData?.[0]?.block_strategy === 'Block Strategy') {
      setFinalDataValue(res);
    } else {
      setFinalDataValue(arr);
    }
  }, [jsonData]);

  /**
   * @param {string} key update key
   * @param {Array} EditedItems edited row items
   */
  const handleTableUpdate = async (key, EditedItems) => {
    // console.log(EditedItems, 'lldkkiiekr');
    // const updatedItems = EditedItems.map((entry) => {
    //   const mdaIdaSites = [...Array(entry.mdaSurvey.length).keys()].map((index) => {
    //     const count = index + 1;
    //     return {
    //       site_id_sentinel1: `S${count}`,
    //       site_id_sentinel2: `R${count}`,
    //       test_type: fieldValue === 'District Strategy' ? entry.test_type || null : entry[`Test Type${count}`] || null,
    //       nbs_survey_date:
    //         fieldValue === 'District Strategy'
    //           ? entry.nbs_survey_date || null
    //           : entry[`NBS Survey Date${count}`] || null,
    //       block_name2: entry[`Block Name2${count}`] || null,
    //       block_name1: entry[`Block Name${count}`] || null,
    //       sentinel_sites_name1: entry[`S${count} Name`] || '',
    //       sentinel_sites_blood_count1: Number(entry[`BSE S${count}`]) || null,
    //       sentinel_sites_possitive1: Number(entry[`Positive S${count}`]) || null,
    //       sentinel_sites_name2: entry[`R${count} Name`] || '',
    //       sentinel_sites_blood_count2: Number(entry[`BSE R${count}`]) || null,
    //       sentinel_sites_possitive2: Number(entry[`Positive R${count}`]) || null,
    //       mda_sites_blocks_id1: parseInt(entry.mdaSurvey[index].mda_sites_blocks_id1 || null, 10),
    //       mda_sites_blocks_id2: parseInt(entry.mdaSurvey[index].mda_sites_blocks_id2 || null, 10),
    //       MDA_IDA_id: entry.mdaSurvey[index].MDA_IDA_id1 || null,
    //       block_date_proposed: entry?.mdaSurvey[index].block_date_proposed,
    //       block_date_planned: entry?.mdaSurvey[index].block_date_planned,
    //       block_date_completed: entry?.mdaSurvey[index].block_date_completed,
    //       block_covered_population: parseInt(entry?.mdaSurvey[index].block_covered_population, 10),
    //       block_population: parseInt(entry?.mdaSurvey[index].block_population, 10),
    //       delete_status: '0',
    //     };
    //   });
    //   return {
    //     proposed_program_activities: 'DA',
    //     state: entry.state,
    //     district: entry.district,
    //     block_strategy: entry.block_strategy,
    //     sentinal: entry.sentinal,
    //     random: entry.random,
    //     date_proposed: entry.date_proposed,
    //     date_planned: entry.date_planned,
    //     date_completed: entry.date_completed,
    //     coverd_population: parseInt(entry.covered_population, 10),
    //     population: entry.population,
    //     // mda_status: entry.mda_status,
    //     updated_by: entry.updated_by,
    //     mdaIdaSiteBlock: mdaIdaSites,
    //     MDA_IDA_id: entry.MDA_IDA_id,
    //     nbs_date_completed: entry.nbs_date_completed,
    //   };
    // });
    const updatedItems = EditedItems.map((entry) => {
      const mdaIdaSites = [...Array(entry.mdaSurvey.length).keys()].map((index) => {
        const count = index + 1;
        // console.log(entry[`NBS Survey Date${count}`], 'lskdkjsijtt');
        return {
          site_id_sentinel1: `S${count}`,
          site_id_sentinel2: `R${count}`,
          test_type: fieldValue === 'District Strategy' ? entry.test_type || null : entry[`Test Type${count}`] || null,
          nbs_survey_date:
            fieldValue === 'District Strategy'
              ? entry.nbs_survey_date || null
              : entry[`NBS Survey Date${count}`] || null,
          block_name2: entry[`Block Name2${count}`] || null,
          block_name1: entry[`Block Name${count}`] || null,
          sentinel_sites_name1: entry[`S${count} Name`] || '',
          sentinel_sites_blood_count1: Number(entry[`BSE S${count}`]) || null,
          sentinel_sites_possitive1: Number(entry[`Positive S${count}`]) || null,
          sentinel_sites_name2: entry[`R${count} Name`] || '',
          sentinel_sites_blood_count2: Number(entry[`BSE R${count}`]) || null,
          sentinel_sites_possitive2: Number(entry[`Positive R${count}`]) || null,
          mda_sites_blocks_id1: parseInt(entry.mdaSurvey[index].mda_sites_blocks_id1 || null, 10),
          mda_sites_blocks_id2: parseInt(entry.mdaSurvey[index].mda_sites_blocks_id2 || null, 10),
          MDA_IDA_id: entry.mdaSurvey[index].MDA_IDA_id1 || null,
          block_date_proposed: entry?.mdaSurvey[index].block_date_proposed,
          block_date_planned: entry?.mdaSurvey[index].block_date_planned,
          block_date_completed: entry?.mdaSurvey[index].block_date_completed,
          block_covered_population: parseInt(entry?.mdaSurvey[index].block_covered_population, 10),
          block_population: parseInt(entry?.mdaSurvey[index].block_population, 10),
          delete_status: '0',
        };
      });
      return {
        proposed_program_activities: 'DA',
        state: entry.state,
        district: entry.district,
        block_strategy: entry.block_strategy,
        sentinal: entry.sentinal,
        random: entry.random,
        date_proposed: entry.date_proposed,
        date_planned: entry.date_planned,
        date_completed: entry.date_completed,
        coverd_population: entry.covered_population,
        population: parseInt(entry.population, 10),
        // mda_status: entry.mda_status,
        updated_by: userAurhId && userAurhId,
        mdaIdaSiteBlock: mdaIdaSites,
        MDA_IDA_id: entry.MDA_IDA_id,
        nbs_date_completed: parseInt(entry.nbs_date_completed, 10),
        block_count: 0,
      };
    });
    if (jsonData?.length > 0) {
      dispatch({ type: UPLOAD_MDA_IDA, payload: { data: finalDataValue, activity: 'DA' } });
      dispatch({ type: GET_MASTER_TEMPLATES });
    } else {
      dispatch({
        type: UPDATE_IDA,
        payload: updatedItems,
      });
    }

    setTimeout(() => {
      dispatch({ type: GET_IDA, payload: { proposed_program_activities: 'DA', strategy: 'District Strategy' } });
    }, 500);
    setIsJson(false);
  };
  /**
   *
   */
  const handleTableClick = (value) => {
    setFieldValue(value);
    setShowTable(true);

    dispatch({ type: GET_IDA, payload: { proposed_program_activities: 'DA', strategy: value } });
    setShowTable(true);
    setTimeout(() => {
      setPrompt(false);
    }, 1000);
  };
  // console.log(fieldValue, 'fieldValue');
  /**
   *
   */
  const handleCancelData = () => {
    setIsJson(false);
    setShowTable(false);
  };
  if (loading) {
    return <Loader />;
  }
  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = async (files) => {
    setFile(files);
    const data = await files.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(worksheet);
    setJsonData(json);
  };
  /**
   *
   */
  const onSubmitFiles = async () => {
    await dispatch({ type: UPLOAD_MDA_IDA, payload: file });
    dispatch({ type: GET_MASTER_TEMPLATES });
  };
  /**
   *
   * @param {*} data
   * @returns
   */
  const handleExcelData = (data) => {
    if (data?.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  // console.log(jsonData, 'sdfksfkjasrr');
  return (
    <Grid container item md={12} spacing={3} className='DaFormat'>
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      {prompt && (
        <Dialog
          open={true}
          // onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogActions>
            <Button onClick={() => handleTableClick('District Strategy')}>District Strategy</Button>
            <Button onClick={() => handleTableClick('Block Strategy')}>Block Strategy</Button>
          </DialogActions>
        </Dialog>
      )}
      <Grid item md={upload === true ? 9 : 11.4} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          {showTable ? (
            <Table
              header={fieldValue === 'Block Strategy' ? thDataBlk : thDataDis}
              rows={isJson ? jsonData : tableElements}
              inputTable
              iconClick="false"
              hideKeyFields={[
                'MDA_IDA_id',
                'state_id',
                'district_id',
                'block_strategy_id',
                'random',
                'sentinal',
                'mdaSurvey',
                'nbs_date_completed',
                'Block_Count',
              ]}
              showSno
              optional={false}
              onSendUpdatedData={handleTableUpdate}
              onCancel={() => handleCancelData()}
              Activity="DA"
              uniqueKey="MDA_IDA_id"
              ExcelData={handleExcelData(jsonData)}
              IsExcelData={isJson}
              screen="input"
            />
          ) : (
            // <DAFormInput OnView={onViewTableData} notify={(show) => setToaster(show)} />
            <MDAFormInput OnView={onViewTableData} Activity="DA" />
          )}
        </Paper>
      </Grid>

      {upload === false && (
        <Tooltip title="File Upload " placement="top">
          <Grid
            onClick={() => BulkUpload === 1 && setUpload(true)}
            padding="10px"
            marginTop="40px"
            className="uploadContainer"
          >
            {/* <img src={CustomIcons.LeftArrow} alt="upload" className="uploadImgFile" /> */}
            {/* <p className="uploadFile">File Upload</p> */}
            <KeyboardDoubleArrowLeftIcon fontSize="large" color="primary" className="uploadImgFile" />
          </Grid>
        </Tooltip>
      )}

      {upload === true && (
        <Grid item md={3} sm={12} xs={12} className="layoutFormat">
          <Paper elevation={3} className="splitPage">
            <Grid>
              <FileUpload
                templateUrl={fieldValue === 'Block Strategy' ? getMasterTemplate.daBlock : getMasterTemplate.daDistrict}
                downloadTemplate
                excelUploader
                sendSelectedFile={(files) => sendSelectedFiles(files)}
                onSubmitFiles={onSubmitFiles}
                handleCancel={() => setUpload(false)}
                onClickHandleView={onClickHandleView}
              />
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default DAForm;
